import { useCallback, useContext, useEffect, useState } from 'react'
import { Moises } from '../../lib/cli'
import { config } from '../../config'
import { ContextShell } from '../../modules/context'

export interface Campaign {
  id: string
  name: string
  endAt: string
  couponCode: string
  eligiblePlans: string[]
  planNames: string[]
  discountPercentage: number
  metadata: any
}

export interface UseCampaign {
  isGlobalCampaign: boolean
  activeCampaign: Campaign | null
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useCampaign = (): UseCampaign => {
  const [loaded, setLoaded] = useState(false)
  const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null)
  const [isGlobalCampaign, setIsGlobalCampaign] = useState(false)
  const { userToken } = useContext(ContextShell)

  const onVerifyActiveGlobalCampaign = useCallback(
    async (_userToken: string) => {
      if (_userToken) {
        MoisesCLI.auth(_userToken)

        const result = await MoisesCLI.getGlobalCampaign()

        if (result.campaign && result?.flags?.offerModeOnWeb) {
          setIsGlobalCampaign(true)
          setActiveCampaign(result?.campaign)
        }
      }
    },
    []
  )

  useEffect(() => {
    if (!loaded || !userToken) {
      return
    }

    setLoaded(true)
    onVerifyActiveGlobalCampaign(userToken)
  }, [loaded, userToken, onVerifyActiveGlobalCampaign])

  return {
    activeCampaign,
    isGlobalCampaign
  }
}
