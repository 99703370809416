import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { ContextShell } from '../../modules/context'
import { useAnnouncements } from './use-announcements'
import { usePersistentDismissible } from '../misc/use-persistent-dismissable'

export type UseAnnouncement = {
  title: string | null
  description: string | null
  image: string | null
  name: string | null
  buttonText: string | null
  show: boolean
  onCloseBanner: (e: any) => void
  onClickBanner: () => void
  startAt: string | null
  showNewFeature: (url: string) => boolean
  onClickNewFeature: (url: string) => void
}

export const useAnnouncement = (): UseAnnouncement => {
  const { push } = useRouter()
  const { value: announcement, loadAnnouncements } = useAnnouncements()
  const {
    user,
    event: { sendEvent }
  } = useContext(ContextShell)

  const haveAnnouncement = useMemo(
    () => announcement !== undefined,
    [announcement]
  )

  useEffect(() => {
    if (user?.id) {
      if (!announcement?.id) {
        loadAnnouncements()
      }
    }
  }, [announcement, loadAnnouncements, user?.id])

  const { show: showBanner, onDismiss } = usePersistentDismissible({
    id: `announcement-banner-${announcement?.id}`,
    type: 'local',
    enabled: !!announcement?.id
  })

  const title = useMemo(() => announcement?.title || '', [announcement])

  const description = useMemo(
    () => announcement?.description || '',
    [announcement]
  )

  const image = useMemo(() => announcement?.image || '', [announcement])

  const name = useMemo(() => announcement?.name || '', [announcement])

  const buttonText = useMemo(
    () => announcement?.button?.label || '',
    [announcement]
  )

  const startAt = useMemo(() => announcement?.startAt || '', [announcement])

  const isUserCreatedBeforeAnnouncement = useMemo(() => {
    if (!user) {
      return false
    }

    const userDate = new Date(user.createdAt as any)
    const announcementDate = new Date(announcement?.startAt as string)

    return userDate < announcementDate
  }, [user, announcement])

  const onCloseBanner = useCallback(
    (e: any) => {
      e?.stopPropagation()
      onDismiss()
    },
    [onDismiss]
  )

  const onClickBanner = useCallback(() => {
    onDismiss()
    sendEvent({
      name: 'announcement_clicked',
      category: 'engagement',
      customAttributes: {
        announcement_name: name || 'announcement'
      }
    })
    push(announcement?.button?.url || '/')
  }, [push, announcement, onDismiss, sendEvent, name])

  const { show: showBadge, onDismiss: onDismissBadge } =
    usePersistentDismissible({
      id: `announcement-badge-${announcement?.id}`,
      type: 'local',
      enabled: !!announcement?.id
    })

  const isUrlCorrect = useCallback(
    (url: string) => !!announcement?.button?.url?.includes(url),
    [announcement?.button?.url]
  )

  const showNewFeature = useCallback(
    (url: string) => (showBadge ? isUrlCorrect(url) : false),
    [showBadge, isUrlCorrect]
  )

  const onClickNewFeature = useCallback(
    (url: string) => {
      if (showBadge && isUrlCorrect(url)) {
        onDismissBadge()
      }
    },
    [showBadge, isUrlCorrect, onDismissBadge]
  )

  return {
    title,
    description,
    image,
    buttonText,
    show: showBanner && haveAnnouncement && isUserCreatedBeforeAnnouncement,
    onCloseBanner,
    onClickBanner,
    startAt,
    name,
    showNewFeature,
    onClickNewFeature
  }
}
