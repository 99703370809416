/* eslint no-console: 0 */
import { useCallback, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useEffectOnce } from 'react-use'
import ReactGA from '../../../lib/react-ga'
import { isDesktopApp } from '../../auth/use-authentication/providers/helpers'
import { useGetUtmStorage } from '../use-utm-storage/use-get-utm-storage'
import { ContextShell } from '../../../modules/context'

interface EventParams {
  name: string
  userId?: string | null
  category: string
  label?: string
  fbEventId?: string
  customAttributes?: {
    [key: string]: string | string[] | number | boolean | undefined
  }
}

export interface UseEvent {
  sendEvent(event: EventParams): any
}

type MessageType = {
  data: {
    type: string
    payload?: any
  }
}

const SESSION_STORAGE_KEY = 'moises:log_events'

export const useEvent = (): UseEvent => {
  const { query } = useRouter()
  const { getUTM } = useGetUtmStorage()
  const { user } = useContext(ContextShell)
  const { id = null, shouldIdentifyOnCIO = false } = user || {}

  useEffectOnce(() => {
    if (query?.log_events) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    }
  })

  const sendEvent = useCallback(
    ({
      name,
      userId = id,
      category,
      label,
      customAttributes,
      fbEventId
    }: EventParams) => {
      if (!userId?.length) {
        return
      }

      const extendedCustomAttributes = {
        ...customAttributes,
        user_id: userId,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        ...getUTM()
      }

      // TODO: função não está sendo definida
      window?.rudderanalytics?.track(name, {
        category,
        label,
        ...{
          ...extendedCustomAttributes,
          shouldIdentifyOnCIO
        }
      })

      let extraFbParams
      if (fbEventId) extraFbParams = { eventID: fbEventId }

      // TODO: função não está sendo definida
      window?.fbq?.(
        'track',
        name,
        {
          category,
          label: label || category,
          ...extendedCustomAttributes
        },
        extraFbParams
      )

      ReactGA.event({
        category,
        action: name,
        label: label || category,
        ...extendedCustomAttributes
      })

      if (sessionStorage.getItem(SESSION_STORAGE_KEY)) {
        console.group('event:', name)
        console.table({
          category,
          label,
          ...extendedCustomAttributes
        })
        console.groupEnd()
      }
    },
    [getUTM, shouldIdentifyOnCIO, id]
  )

  useEffect(() => {
    if (!shouldIdentifyOnCIO) {
      const handleMessage = (event: MessageType): void => {
        const message = event.data

        if (
          ['events-voice-studio', 'events-mastering'].includes(message.type)
        ) {
          sendEvent(message.payload)
        }
      }

      window.addEventListener('message', handleMessage)
      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
    return () => {}
    // eslint-disable-next-line
  }, [sendEvent])

  return {
    sendEvent
  }
}
