import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { useEvent } from '../../../hooks/analytics/use-event/use-event'
import { Icon } from '../../icon'
import { Button } from '../../button'
import styles from './styles.module.scss'

type Props = {
  show: boolean
  image: string | null
  title: string | null
  name: string | null
  description: string | null
  buttonText: string | null
  onClickBanner: () => void
  onCloseBanner: (e: any) => void
}

export const AnnouncementBanner: React.FC<Props> = ({
  show,
  image,
  title,
  description,
  buttonText,
  onClickBanner,
  onCloseBanner,
  name
}) => {
  const [delayToShow, setDelayToShow] = useState(true)
  const timeoutRef = useRef<any>(null)
  const { sendEvent } = useEvent()

  const isVideo = useMemo(() => image?.endsWith('.mp4'), [image])

  const sendEventOneTime = useCallback(() => {
    const announcementViewed = localStorage.getItem(
      `announcement-event-${name}`
    )

    if (!announcementViewed) {
      localStorage.setItem(`announcement-event-${name}`, 'true')
      sendEvent({
        name: 'announcement_viewed',
        category: 'engagement',
        customAttributes: {
          announcement_name: name || 'announcement'
        }
      })
    }
  }, [name, sendEvent])

  useEffect(() => {
    if (name) {
      sendEventOneTime()
    }
    // eslint-disable-next-line
  }, [name])

  useEffect(() => {
    if (show) {
      timeoutRef.current = setTimeout(() => {
        setDelayToShow(false)
      }, 600)
    } else {
      clearTimeout(timeoutRef.current)
      setDelayToShow(true)
    }
  }, [show])

  if (!show || delayToShow) {
    return null
  }
  return (
    <div
      aria-hidden
      role="button"
      className={styles.container}
      onClick={onClickBanner}
    >
      {isVideo ? (
        <video autoPlay loop muted className={styles.videoBackground}>
          <source src={image || undefined} type="video/mp4" />
        </video>
      ) : (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
      <button
        type="button"
        aria-label="Close"
        className={styles.closeButton}
        onClick={onCloseBanner}
      >
        <Icon name="close" width={20} height={20} />
      </button>
      <div className={styles.containerContent}>
        <span className={styles.badge}>{t`new_label_announcement`}</span>
        <div className={styles.labelContent}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>

        <Button title={buttonText} long quiet />
      </div>
    </div>
  )
}
