import React, { useCallback, useContext, useEffect } from 'react'
import { t } from '@lingui/macro'
import styles from './referral-link-list.module.scss'
import { Skeleton } from './skeleton'
import { replacePlaceholders } from '../../../lib/misc/replace-placeholders'
import { ContextShell } from '../../context'
import { Icon } from '../../../components/icon'
import { Button } from '../../../components/button'
import { useStoreModals } from '../../../hooks/modals/use-store-modals'
import {
  ReferralLink,
  useReferralLinks
} from '../../../hooks/referral-link/use-referral-links'
import { usePlanName } from '../../../hooks/plans/use-plan-name'

export const ReferralLinkList: React.FC = () => {
  const {
    user,
    event: { sendEvent },
    toast: { add: addToast }
  } = useContext(ContextShell)
  const { plan } = usePlanName()
  const { referralLinks, loading, success } = useReferralLinks()

  const {
    modalReferralLink: { onDismiss: onDismissModal }
  } = useStoreModals()

  const onCopyLink = useCallback(
    (referralLink: ReferralLink) => {
      navigator.clipboard.writeText(referralLink.url).then(
        () => {
          onDismissModal()
          sendEvent({
            userId: user?.id,
            name: 'copied_referral_link',
            category: 'engagement'
          })
          addToast({
            icon: null,
            type: 'success',
            closable: true,
            description: t`referral_link_copied_success`
          })
        },
        () => {
          addToast({
            icon: null,
            type: 'error',
            closable: true,
            description: 'An error occurred, try again.'
          })
        }
      )
    },
    [addToast, onDismissModal, sendEvent, user?.id]
  )

  useEffect(() => {
    if (user?.id && success) {
      sendEvent({
        userId: user.id,
        name: 'viewed_referral_modal',
        category: 'engagement',
        customAttributes: {
          remaining_referral_links: referralLinks.length
        }
      })
    }
  }, [referralLinks, sendEvent, user?.id, success])

  const hasReferralLinks = referralLinks.length

  if (loading) {
    return <Skeleton />
  }

  return (
    <div>
      <div className={styles.info}>
        {hasReferralLinks ? (
          <>
            <p className={styles.description}>
              {replacePlaceholders(t`refer_friend_description`, {
                number: 7,
                plan
              })}
            </p>
            <p className={styles.tip}>
              {replacePlaceholders(t`refer_friend_message`, { plan })}
            </p>
          </>
        ) : (
          <>
            <Icon name="info" width={40} height={40} className={styles.icon} />
            <p>
              {replacePlaceholders(t`no_more_referral_links_availabe`, {
                total: 5
              })}
            </p>
          </>
        )}
      </div>
      {!!hasReferralLinks && (
        <>
          <p className={styles.title}>
            {replacePlaceholders(
              referralLinks.length > 1
                ? t`referral_link_total_available_plural`
                : t`referral_link_total_available_singular`,
              { number: referralLinks.length }
            )}
          </p>
          <ul className={styles.list}>
            {referralLinks.map((referralLink) => (
              <li key={referralLink.id}>
                <div className={styles.item}>
                  <input
                    type="text"
                    value={referralLink.url}
                    className={styles.input}
                    readOnly
                  />
                  <Button
                    className={styles.copy}
                    type="button"
                    small
                    title={t`copy_link`}
                    radius={1}
                    onClick={() => onCopyLink(referralLink)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
