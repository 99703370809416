import { useAsyncFn } from 'react-use'
import { Moises } from '../../lib/cli'
import { config } from '../../config'
import { useFirebaseToken } from '../auth/use-firebase-token'

type Announcement = {
  lang?: string | null
  image?: string | null
  title?: string | null
  name?: string | null
  button?: {
    url?: string | null
    label?: string | null
    newTab?: boolean
  }
  description?: string | null
  startAt?: string | null
  id?: string | null
}

type UseAnnouncements = {
  loading: boolean
  error?: Error | undefined
  value?: Announcement | undefined
  loadAnnouncements: () => Promise<Announcement | undefined>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useAnnouncements = (): UseAnnouncements => {
  const userToken = useFirebaseToken()

  const [state, loadAnnouncements] = useAsyncFn(async () => {
    if (userToken) {
      MoisesCLI.auth(userToken)

      const response = await MoisesCLI.getAnnouncements()

      if (!response?.announcement?.length) {
        return undefined
      }

      return {
        ...response.announcement[0].cmsPayload.web.sideBanner[0],
        id: response.announcement[0].id,
        startAt: response.announcement[0].startAt,
        name: response.announcement[0].name
      }
    }

    return undefined
  }, [userToken])

  return {
    ...state,
    loadAnnouncements
  }
}
