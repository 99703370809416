import { gql } from 'graphql-request'

interface AnnouncementCli {
  getAnnouncements(): Promise<AnnouncementResponse>
}

type AnnouncementType = {
  id: string | null
  name: string | null
  cmsPayload: {
    web: {
      sideBanner: [
        {
          id: string
          lang: string
          title: string
          description: string
          image: string
          button: {
            url: string
            label: string
            newTab: boolean
          }
        }
      ]
    }
  }
  startAt: string | null
}

type AnnouncementResponse = {
  announcement: AnnouncementType[]
}

const Announcement = (graphQL: any): AnnouncementCli => {
  const getAnnouncements = async (): Promise<any> => {
    const variables = {}
    const query = gql`
      query {
        announcement {
          id
          name
          cmsPayload
          startAt
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    getAnnouncements
  }
}

export default Announcement
