import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { Icon } from '../../components/icon'
import {
  DownloadQueue,
  useStoreDownloadQueue
} from '../../hooks/download/use-store-download-queue'
import { ModalConfirmationColumn } from '../../components/modals/modal-confirmation-column'
import styles from './download-queue-container.module.scss'
import { ItemQueue } from './item-queue'

type MessageType = {
  data: {
    type: string
    payload?: any
  }
}

export const DownloadQueueContainer: React.FC = () => {
  const { i18n } = useLingui()
  const {
    list: queueDownload,
    remove: onRemove,
    add: onAddDownload,
    addAll: onAddAllDownload,
    addMastering,
    onOpenModal,
    clear: onClearDownloadQueue,
    onDismissModal,
    showModal
  } = useStoreDownloadQueue()

  const [opened, setOpened] = useState(true)
  const toggleQueue = useCallback(() => setOpened(!opened), [opened])

  useEffect(() => {
    const handleMessage = (event: MessageType): void => {
      const message = event.data

      if (message.type === 'export-voice-studio') {
        onAddDownload(message.payload)
      }

      if (message.type === 'export-all-voice-studio') {
        onAddAllDownload(message.payload)
      }

      if (message.type === 'export-mastering') {
        addMastering(message.payload)
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onAddDownload, onAddAllDownload, addMastering])

  if (!queueDownload.length) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={classnames(styles.header, {
            [styles.opened]: opened
          })}
        >
          <button
            type="button"
            onClick={toggleQueue}
            className={classnames(styles.button, styles.title)}
          >
            <Icon
              name="zip"
              className={classnames(styles.iconZip, {
                [styles.hide]: !opened
              })}
            />

            <p className={styles.label}>
              {i18n._(t`label.download.preparing`)}
            </p>

            <Icon
              name="arrow-fill"
              className={classnames(styles.iconArrow, {
                [styles.invert]: opened
              })}
            />
          </button>

          <button
            type="button"
            className={styles.button}
            onClick={onOpenModal}
            aria-label="Close"
          >
            <Icon name="close" className={styles.iconClose} />
          </button>
        </div>

        <div
          className={classnames(styles.content, {
            [styles.show]: opened
          })}
        >
          {queueDownload.map((i: DownloadQueue) => (
            <ItemQueue key={`${i.url}-${i.title}`} {...i} onRemove={onRemove} />
          ))}
        </div>
      </div>

      <ModalConfirmationColumn
        loading={false}
        isOpen={showModal}
        showCloseButton={false}
        onDismiss={onDismissModal}
        onConfirm={onClearDownloadQueue}
        title={i18n._(t`label.download.title`)}
        info={i18n._(t`label.download.description`)}
        confirmLabel={i18n._(t`actions.download.cancel`)}
        cancelLabel={i18n._(t`actions.download.continue`)}
        loadingLabel={i18n._(t`actions.download.continue`)}
      />
    </>
  )
}
