import { useCallback, useContext, useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import { off, on } from '../../../lib/events'
import { ContextShell } from '../../../modules/context'
import { Playlist } from '../../../modules/playlist/playlists-sidebar/types'

interface UseListPlaylist {
  error: boolean
  loading: boolean
  playlists: Playlist[] | null
  mutate(): void
  fetchPlaylists(): Promise<void>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useFetchPlaylists = ({
  asGuest = true,
  created = true,
  type
}: {
  asGuest?: boolean
  created?: boolean
  type?: string[]
}): UseListPlaylist => {
  const { userToken } = useContext(ContextShell)

  const [state, fetchPlaylists] = useAsyncFn(async () => {
    if (!userToken) {
      return null
    }

    MoisesCLI.auth(userToken)

    try {
      const list = await MoisesCLI.fetchPlaylists({ asGuest, created, type })

      if (!asGuest) {
        return list.filter((playlist: Playlist) => !playlist.isGlobal)
      }

      return list || null
    } catch (e: any) {
      // increaseErrorCount()
      // eslint-disable-next-line no-console
      console.error('Error fetching playlists')
      throw new Error(e)
    }
  }, [userToken, asGuest])

  const mutate = useCallback(() => fetchPlaylists(), [fetchPlaylists])

  useEffect(() => {
    on('playlist:create-playlist-done', mutate)
    on('playlist:update-playlist', mutate)

    return () => {
      off('playlist:create-playlist-done', mutate)
      off('playlist:update-playlist', mutate)
    }
  }, [mutate])

  return {
    error: !!state.error,
    loading: state.loading,
    playlists: state.value || null,
    mutate,
    fetchPlaylists
  }
}
