import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useTimeoutFn } from 'react-use'
import { ContextShell } from '../../../modules/context'

export const useRedirectToLoginOnAuthenticationTimeout = (): void => {
  const { push, pathname, asPath } = useRouter()
  const { userToken } = useContext(ContextShell)

  const [isTokenTimeoutReady, cancelTokenTimeout] = useTimeoutFn(() => {
    if (
      !userToken &&
      !['/login', '/logout', '/reset-password'].includes(pathname)
    ) {
      push(`/login?redirect=${asPath}`)
    }
  }, 5000)

  useEffect(() => {
    const isTokenTimeoutPending = isTokenTimeoutReady() === false
    if (userToken && isTokenTimeoutPending) {
      cancelTokenTimeout()
    }
  }, [cancelTokenTimeout, isTokenTimeoutReady, userToken])
}
