import React, { ReactElement } from 'react'
import { useToast } from '../../hooks/toast'
import { useFirebaseToken } from '../../hooks/auth/use-firebase-token'
import { useGetUserData } from '../../hooks/user/use-get-user-data/use-get-user-data'
import { useEvent } from '../../hooks/analytics/use-event/use-event'
import { ShellContext } from '../../types'
import { useSidebar } from '../../hooks/sidebar'

export const ContextShell = React.createContext<ShellContext>({
  user: null,
  userToken: null,
  silentRetry: () => {},
  event: {
    sendEvent: () => {}
  },
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  },
  sidebar: {
    isOpenedSidebar: true,
    onCollapsedSidebar: () => {},
    onOpenSidebar: () => {}
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const toast = useToast()
  const event = useEvent()
  const userToken = useFirebaseToken()
  const sidebar = useSidebar()

  const { user, silentRetry } = useGetUserData({ userToken })

  return (
    <ContextShell.Provider
      value={{
        user,
        userToken,
        silentRetry,
        event,
        toast,
        sidebar
      }}
    >
      {children}
    </ContextShell.Provider>
  )
}
