import Head from 'next/head'
import { ReactElement } from 'react'
import { Scripts } from './scripts'
import { RootContainerType } from './types'

export const RootContainer: React.FC<RootContainerType> = ({
  children
}): ReactElement => {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon-32x32.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta
          name="keywords"
          content="track separation, vocal remover, karaoke tracks, ai mastering, free mastering"
        />
      </Head>
      <Scripts />
      <div id="shell-app">{children}</div>
    </>
  )
}
