import { useCallback, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { useFetchReferralLinks } from '../use-fetch-referral-links'

export interface ReferralLink {
  id: string
  url: string
}

export interface UseReferralLinks {
  loading: boolean
  error: boolean
  success: boolean
  referralLinks: ReferralLink[]
}

export const useReferralLinks = (): UseReferralLinks => {
  const [success, setSuccess] = useState(false)
  const { fetchReferralLinks, loading, error } = useFetchReferralLinks()

  const [
    referralLinks,
    { set: setListReferralLinks, clear: clearReferralLinks }
  ] = useList<ReferralLink>([])

  const fetchData = useCallback(async () => {
    const list: ReferralLink[] = await fetchReferralLinks()

    return list
  }, [fetchReferralLinks])

  useEffect(() => {
    const start = async (): Promise<void> => {
      const list: ReferralLink[] = await fetchData()

      if (list?.length) {
        setListReferralLinks(list)
      }

      setSuccess(true)
    }

    start()

    return (): void => {
      clearReferralLinks()
    }
  }, [fetchData, setListReferralLinks, clearReferralLinks])

  return {
    loading,
    error,
    success,
    referralLinks
  }
}
