import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon } from '../../icon'
import styles from './styles.module.scss'
import { UseCampaignCountdown } from '../../../hooks/campaign'

export const BannerCountdown: React.FC<UseCampaignCountdown> = ({
  show,
  title,
  description,
  image,
  countdown,
  onClickBannerCountdown,
  onCloseBannerCountdown
}) => {
  const { i18n } = useLingui()
  const { days, hours, minutes, ended } = countdown
  const [delayToShow, setDelayToShow] = useState(true)
  const timeoutRef = useRef<any>(null)

  useEffect(() => {
    if (show) {
      timeoutRef.current = setTimeout(() => {
        setDelayToShow(false)
      }, 600)
    } else {
      clearTimeout(timeoutRef.current)
      setDelayToShow(true)
    }
  }, [show])

  if (!show || ended || delayToShow) {
    return null
  }

  return (
    <div
      aria-hidden
      role="button"
      className={styles.container}
      onClick={onClickBannerCountdown}
    >
      <button
        type="button"
        aria-label="Close"
        className={styles.button}
        onClick={onCloseBannerCountdown}
      >
        <Icon name="close" width={20} height={20} />
      </button>

      {image && (
        <div className={styles.image}>
          <img alt={`${title}`} src={image} width={120} />
        </div>
      )}

      <div className={styles.labelContent}>
        <p className={styles.label}>{title}</p>
        <p className={styles.label}>{description}</p>
      </div>

      <div className={styles.clock}>
        {days && days > 0 ? (
          <div className={styles.column}>
            <p className={styles.labelBig}>{days}</p>
            <p className={styles.labelSmall}>
              {i18n._(t`banner_days_display`)}
            </p>
          </div>
        ) : null}

        <div className={styles.column}>
          <p className={styles.labelBig}>{hours}</p>
          <p className={styles.labelSmall}>{i18n._(t`banner_hours_display`)}</p>
          <p className={classNames(styles.labelBig, styles.colon)}>:</p>
        </div>

        <div className={styles.column}>
          <p className={styles.labelBig}>{minutes}</p>
          <p className={styles.labelSmall}>{i18n._(t`banner_mins_display`)}</p>
        </div>
      </div>
    </div>
  )
}
