import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useEffectOnce, useInterval } from 'react-use'
import { ContextShell } from '../../context'
import { useFetchPlaylists } from '../../../hooks/playlist/use-fetch-playlists'
import { usePlaylist } from '../../../hooks/playlist/use-playlist'
import { Icon } from '../../../components/icon'
import { ModalConfirmation } from '../../../components/modals/modal-confirmation'
import { Empty } from './empty'
import styles from './playlists-sidebar.module.scss'
import { Skeleton } from './skeleton'
import { usePageVisibility } from '../../../hooks/misc/use-page-visibility'
import { getCustomAttributesPlaylist } from './utils'
import { Playlist } from './types'
import { PlaylistItem } from '../playlist-item'
import { ActionsPlaylist } from '../actions-playlist'
import { UnsubscribePlaylist } from '../unsubscribe-playlist'
import { DeletePlaylist } from '../delete-playlist'
import { EditPlaylist } from '../edit-playlist'

export const PlaylistsSidebar: React.FC = (): ReactElement => {
  const { user } = useContext(ContextShell)
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const { playlists, fetchPlaylists } = useFetchPlaylists({
    asGuest: true,
    type: ['JAMSESSION', 'COLLECTION', 'BRANDED']
  })
  const pageIsActive = usePageVisibility()
  const isNotLibraryPage = useMemo(
    () => !pathname.includes('/library'),
    [pathname]
  )

  const {
    openModalConfirmation,
    onDismissModalConfirmation,
    onConfirmAddTaskToPlaylist
  } = usePlaylist()

  const webSetlistMembersToggle = user?.featureFlags?.webSetlistMembers

  const onCreatePlaylist = useCallback(() => {
    push('/setlist/new')
  }, [push])

  useEffectOnce(() => {
    fetchPlaylists()
  })

  useInterval(
    () => {
      fetchPlaylists()
    },
    !pageIsActive ? null : isNotLibraryPage ? 30000 : 15000
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>{i18n._(t`library.playlists`)}</p>

        <button
          id="new_playlist_button"
          type="button"
          onClick={onCreatePlaylist}
          className={styles.button}
        >
          <Icon name="plus" width={20} height={20} className={styles.icon} />{' '}
          {i18n._(t`library.new.playlist`)}
        </button>
      </div>

      <div className={styles.list}>
        {!user?.id || !playlists ? <Skeleton /> : null}

        {playlists && playlists.length === 0 && (
          <Empty onCreatePlaylist={onCreatePlaylist} />
        )}

        {playlists &&
          playlists.map((playlist: Playlist, i: number) => {
            const isOwner = playlist?.creator?.id === user?.id
            const allowEdit = webSetlistMembersToggle && !playlist?.viewOnly

            return (
              <PlaylistItem
                index={i}
                key={playlist.id}
                {...playlist}
                unreadCount={
                  user?.featureFlags?.webNotification
                    ? playlist?.notifications?.unreadCount
                    : undefined
                }
                isShared={playlist.isShared || !!playlist.guests.totalCount}
                ActionsPlaylist={
                  <ActionsPlaylist
                    small
                    right
                    events={getCustomAttributesPlaylist(
                      { ...playlist, isOwner },
                      {
                        source: 'playlist_tab'
                      }
                    )}
                    playlistId={playlist.id}
                    UnsubscribePlaylist={!isOwner && <UnsubscribePlaylist />}
                    DeletePlaylist={isOwner && <DeletePlaylist />}
                    EditPlaylist={
                      allowEdit || isOwner ? (
                        <EditPlaylist
                          title={playlist.name}
                          description={playlist.description}
                        />
                      ) : null
                    }
                  />
                }
              />
            )
          })}
      </div>

      {openModalConfirmation && (
        <ModalConfirmation
          loading={false}
          isOpen={openModalConfirmation}
          onDismiss={onDismissModalConfirmation}
          onConfirm={onConfirmAddTaskToPlaylist}
          title={i18n._(t`song.already.playlist.title`)}
          info={i18n._(t`song.already.playlist.description`)}
          confirmLabel={i18n._(t`song.already.playlist.cta2`)}
          cancelLabel={i18n._(t`song.already.playlist.cta1`)}
          loadingLabel={i18n._(t`label.loading`)}
        />
      )}
    </div>
  )
}
